const items_en = {
  PF_ITEM_00001: "Login",
  PF_ITEM_00002: "Japan",
  PF_ITEM_00003: "Region 2",
  PF_ITEM_00004: "Set/Reset Password",
  PF_ITEM_00005: "User ID",
  PF_ITEM_00006: "Password",
  PF_ITEM_00007: "New Password",
  PF_ITEM_00008: "Confirm Password",
  PF_ITEM_00009: "Change Password",
  PF_ITEM_00010: "Drop the file here.",
  PF_ITEM_00011: "Click to browse for a file.",
  PF_ITEM_00012: "Please select the file.",
  PF_ITEM_00013: "Total",
  PF_ITEM_00014: "items",
  PF_ITEM_00015: "User Info",
  PF_ITEM_00016: "Name",
  PF_ITEM_00017: "Company",
  PF_ITEM_00018: "Email Address",
  PF_ITEM_00019: "Welcome, {0}! Please select the menu on the left.",
  PF_ITEM_00020: "Company Master",
  PF_ITEM_00021: "Click here to enter search criteria.",
  PF_ITEM_00022: "Company Code",
  PF_ITEM_00023: "Company Name \n(Abbrev.)",
  PF_ITEM_00024: "Company AuthN \nCode",
  PF_ITEM_00025: "Postal Code",
  PF_ITEM_00026: "Address",
  PF_ITEM_00027: "Search Result",
  PF_ITEM_00028: "Company Name \n(Official)",
  PF_ITEM_00029: "Company \nClassification",
  PF_ITEM_00030: "Remark",
  PF_ITEM_00031: "Details",
  PF_ITEM_00032: "Display Company Master",
  PF_ITEM_00033: "Company ID \n(Abbrev.)",
  PF_ITEM_00034: "Customer Comp \n(OEM)",
  PF_ITEM_00035: "Related Customer \nComp",
  PF_ITEM_00036: "Data Linkage \nMethod",
  PF_ITEM_00037: "New Entry for Company Master",
  PF_ITEM_00038: "Company",
  PF_ITEM_00039: "Update Company Master",
  PF_ITEM_00040: "Company Master Search Result Screen",
  PF_ITEM_00041: "Company Master Search Screen",
  PF_ITEM_00042: "Trading Comp",
  PF_ITEM_00043: "Steel Maker",
  PF_ITEM_00044: "Steel Processing \nComp",
  PF_ITEM_00045: "PO NO. (PF)",
  PF_ITEM_00046: "PO NO.",
  PF_ITEM_00047: "Master Ref.",
  PF_ITEM_00048: "Item Code",
  PF_ITEM_00049: "Item Name",
  PF_ITEM_00050: "Use",
  PF_ITEM_00051: "PO Comp Code",
  PF_ITEM_00052: "PO Comp",
  PF_ITEM_00053: "PO Group Code",
  PF_ITEM_00054: "PO Group",
  PF_ITEM_00055: "PO Comp 2 Code",
  PF_ITEM_00056: "PO Comp 2",  
  PF_ITEM_00057: "Delivery Place Code",
  PF_ITEM_00058: "Delivery Place",
  PF_ITEM_00059: "Delivery Place Address",
  PF_ITEM_00060: "SO Comp Code",
  PF_ITEM_00061: "SO Comp",
  PF_ITEM_00062: "SO Group Code",
  PF_ITEM_00063: "SO Group",
  PF_ITEM_00064: "Steel Processing Comp Code",
  PF_ITEM_00065: "Steel Processing Comp",
  PF_ITEM_00066: "Steel Processing Group \nCode",
  PF_ITEM_00067: "Steel Processing \nGroup",
  PF_ITEM_00068: "Shape",
  PF_ITEM_00069: "Spec.",
  PF_ITEM_00070: "Thickness",
  PF_ITEM_00071: "Width",
  PF_ITEM_00072: "Length",
  PF_ITEM_00073: "Size 4",
  PF_ITEM_00074: "Size 5",
  PF_ITEM_00075: "Lead Time",
  PF_ITEM_00076: "Delivery Date",
  PF_ITEM_00077: "Delivery Time",
  PF_ITEM_00078: "Order Qty. (pc)",
  PF_ITEM_00079: "Order Qty. (kg)",
  PF_ITEM_00080: "Related PO NO.",
  PF_ITEM_00081: "Transaction Type",
  PF_ITEM_00082: "PO Comp Notes",
  PF_ITEM_00083: "SO Comp Notes",
  PF_ITEM_00084: "Special Notes",
  PF_ITEM_00085: "Status",
  PF_ITEM_00086: "Last Update by",
  PF_ITEM_00087: "Comment History",
  PF_ITEM_00088: "Add Comment",
  PF_ITEM_00089: "Save",
  PF_ITEM_00090: "Close",
  PF_ITEM_00091: "Order Process Cat.",
  PF_ITEM_00092: "Changed Items",
  PF_ITEM_00093: "＋",
  PF_ITEM_00094: "ー",
  PF_ITEM_00095: "Re-change",
  PF_ITEM_00096: "Approve",
  PF_ITEM_00097: "Reject",
  PF_ITEM_00098: "Confirm",
  PF_ITEM_00099: "Shipment Indicator",
  PF_ITEM_00100: "Planned Shipment Date",
  PF_ITEM_00101: "Planned Total Qty. (pc)",
  PF_ITEM_00102: "Planned Total Qty. (kg)",
  PF_ITEM_00103: "Shipment Process Cat.",
  PF_ITEM_00104: "Packaging No.",
  PF_ITEM_00105: "Planned Qty. (pc)",
  PF_ITEM_00106: "Planned Qty. (kg)",
  PF_ITEM_00107: "Inspection No.",
  PF_ITEM_00108: "Actual Shipped Date",
  PF_ITEM_00109: "Actual Total Qty. (pc)",
  PF_ITEM_00110: "Actual Total Qty. (kg)",
  PF_ITEM_00111: "Actual Shipment",
  PF_ITEM_00112: "Planned Shipment",
  PF_ITEM_00113: "Actual Qty. (pc)",
  PF_ITEM_00114: "Actual Qty. (kg)",
  PF_ITEM_00115: "Cancel",
  PF_ITEM_00116: "Copy to Actual Shipment",
  PF_ITEM_00117: "Claim Info",
  PF_ITEM_00118: "Returned Date",
  PF_ITEM_00119: "Returned Qty. (pc)",
  PF_ITEM_00120: "Returned Qty. (kg)",
  PF_ITEM_00121: "Order Information",
  PF_ITEM_00122: "Order Change",
  PF_ITEM_00123: "Shipping Information",
  PF_ITEM_00124: "Claim Information",
  PF_ITEM_00125: "Lead Time Master",
  PF_ITEM_00126: "PO Comp",
  PF_ITEM_00127: "PO Group",
  PF_ITEM_00128: "PO Comp 2",
  PF_ITEM_00129: "Delivery Place",
  PF_ITEM_00130: "SO Comp",
  PF_ITEM_00131: "SO Group",
  PF_ITEM_00132: "Steel Processing Comp",
  PF_ITEM_00133: "Steel Processing Group",
  PF_ITEM_00134: "Date Indicator 1 ",
  PF_ITEM_00135: "Date Indicator 2",
  PF_ITEM_00136: "Last Update Date",
  PF_ITEM_00137: "Hour",
  PF_ITEM_00138: "Minute",
  PF_ITEM_00139: "Claim Info",
  PF_ITEM_00140: "Sort Key",
  PF_ITEM_00141: "Ascending Order (ASC)",
  PF_ITEM_00142: "Descending Order (DESC)",
  PF_ITEM_00143: "Common Delivery Date Management Search",
  PF_ITEM_00144: "～",
  PF_ITEM_00145: "Upload Group Master",
  PF_ITEM_00146: "Group Master",
  PF_ITEM_00149: "Company Name",
  PF_ITEM_00150: "Delivery Place Master",
  PF_ITEM_00151: "File",
  PF_ITEM_00152: "Delivery Mgmt Table",
  PF_ITEM_00153: "Order Qty. (pc)",
  PF_ITEM_00154: "Order Qty. (kg)",
  PF_ITEM_00155: "PO NO.",
  PF_ITEM_00156: "Shipment Qty. (pc)",
  PF_ITEM_00157: "Shipment Qty. (kg)",
  PF_ITEM_00158: "Entry for Shipment Qty.",
  PF_ITEM_00159: "Upload Shipment Qty.",
  PF_ITEM_00160: "Upload is in progress. Please check the upload status on the Upload Batch Inquiry screen.",
  PF_ITEM_00161: "Lead Time Master (by Item Code)",
  PF_ITEM_00163: "Upload completed!",
  PF_ITEM_00164: "Action",
  PF_ITEM_00165: "No.",
  PF_ITEM_00166: "Start Time",
  PF_ITEM_00167: "End Time",
  PF_ITEM_00168: "File Name",
  PF_ITEM_00169: "Total",
  PF_ITEM_00170: "Number of Entry",
  PF_ITEM_00171: "Number of Error",
  PF_ITEM_00172: "Process Cat.",
  PF_ITEM_00173: "Name of delivery destination (for display)",
  PF_ITEM_00174: "Name of delivery destination (official name)",
  PF_ITEM_00175: "Upload Batch Status",
  PF_ITEM_00178: "Upload Daily Steel Order",
  PF_ITEM_00179: "Phone Number",
  PF_ITEM_00180: "Location Code",
  PF_ITEM_00181: "Location Name",
  PF_ITEM_00182: "Display Location Master",
  PF_ITEM_00183: "Location",
  PF_ITEM_00184: "New Entry for Location Master",
  PF_ITEM_00185: "Location Master Search Result Screen",
  PF_ITEM_00186: "Location Master Search Screen",
  PF_ITEM_00187: "Update Location Master",
  PF_ITEM_00188: "User Name",
  PF_ITEM_00189: "Role",
  PF_ITEM_00190: "Upload Delivery Place Master",
  PF_ITEM_00191: "New Entry for User Master",
  PF_ITEM_00192: "Input Format: <Last Name> + space + <First Name>",
  PF_ITEM_00193: "User Master Search Result Screen",
  PF_ITEM_00194: "User Master Search Screen",
  PF_ITEM_00195: "User",
  PF_ITEM_00196: "Display User Master",
  PF_ITEM_00197: "Update User Master",
  PF_ITEM_00198: "Display User Master Screen",
  PF_ITEM_00199: "Display Location Master Screen",
  PF_ITEM_00200: "Display Company Master Screen",
  PF_ITEM_00201: "Error Message",
  PF_ITEM_00202: "Entry and Display Item",
  PF_ITEM_00203: "Item Classification",
  PF_ITEM_00204: "Search Base Date",
  PF_ITEM_00205: "Factory",
  PF_ITEM_00206: "Vehicle Models",
  PF_ITEM_00207: "Model Series",
  PF_ITEM_00208: "Trading Comp",
  PF_ITEM_00209: "Steel R/M Code",
  PF_ITEM_00210: "Customer",
  PF_ITEM_00211: "Steel Processing \nComp",
  PF_ITEM_00212: "Steel Processing \nLocation",
  PF_ITEM_00213: "Mill Code",
  PF_ITEM_00214: "Mill Name",
  PF_ITEM_00215: "Steel Type Code",
  PF_ITEM_00216: "Steel Type",
  PF_ITEM_00217: "Coating Weight",
  PF_ITEM_00218: "Thinner Gauge",
  PF_ITEM_00219: "Master Coil Code \nfor BF",
  PF_ITEM_00220: "Delivery Place \n(Steel R/M)",
  PF_ITEM_00222: "Top Level Item",
  PF_ITEM_00223: "Component Level Item",
  PF_ITEM_00224: "Steel F/G",
  PF_ITEM_00225: "Steel R/M",
  PF_ITEM_00226: "Item Code",
  PF_ITEM_00227: "If specifying multiple conditions, please enter them separated by",
  PF_ITEM_00228: "Item Name",
  PF_ITEM_00229: "For Parts Supplier,Item Name",
  PF_ITEM_00230: "For Trading Comp,Spec+Size",
  PF_ITEM_00231: "Spec+Size",
  PF_ITEM_00232: "Coil",
  PF_ITEM_00233: "Sheet",
  PF_ITEM_00234: "Blank",
  PF_ITEM_00235: "Trapezoid Blank",
  PF_ITEM_00236: "Direct Shipment",
  PF_ITEM_00237: "Steel Pipe",
  PF_ITEM_00238: "Steel Bar",
  PF_ITEM_00239: "Steel Wire",
  PF_ITEM_00240: "Others",
  PF_ITEM_00241: "Ver.",
  PF_ITEM_00242: "Valid Start Date",
  PF_ITEM_00243: "Valid End Date",
  PF_ITEM_00244: "Upload Item Entry",
  PF_ITEM_00245: "Error message list",
  PF_ITEM_00246: "All",
  PF_ITEM_00247: "Top Level Item",
  PF_ITEM_00248: "New Entry for Item",
  PF_ITEM_00249: "Item Name (Abbrev.)",
  PF_ITEM_00250: "Item Name (Official)",
  PF_ITEM_00251: "Unit",
  PF_ITEM_00252: "Customer Comp Code",
  PF_ITEM_00253: "Item Linkage \nClassification",
  PF_ITEM_00254: "Applicable Item for Linkage ",
  PF_ITEM_00255: "Not Applicable Item for Linkage ",
  PF_ITEM_00256: "Spot Item",
  PF_ITEM_00257: "Reason for \nChange",
  PF_ITEM_00258: "Trading Comp Code",
  PF_ITEM_00259: "GLNO.",
  PF_ITEM_00260: "Max. OD",
  PF_ITEM_00261: "Min. OD",
  PF_ITEM_00262: "Max. Coil Weight",
  PF_ITEM_00263: "Min. Coil Weight",
  PF_ITEM_00264: "Packaged Qty. (pc)",
  PF_ITEM_00265: "Special Spec.",
  PF_ITEM_00266: "Order Unit",
  PF_ITEM_00267: "Min. Order Qty",
  PF_ITEM_00268: "Outer Panel",
  PF_ITEM_00269: "BF Code",
  PF_ITEM_00270: "Material Code",
  PF_ITEM_00271: "Notification Type",
  PF_ITEM_00272: "Steel Processing \nLocation Code",
  PF_ITEM_00273: "Item",
  PF_ITEM_00274: "Customer Comp",
  PF_ITEM_00275: "Notification Date",
  PF_ITEM_00276: "Notification List Search",
  PF_ITEM_00277: "Comment",
  PF_ITEM_00278: "Place Of Delivery Code",
  PF_ITEM_00280: "Item Details",
  PF_ITEM_00281: "Sun",
  PF_ITEM_00282: "Mon",
  PF_ITEM_00283: "Tue",
  PF_ITEM_00284: "Wed",
  PF_ITEM_00285: "Thu",
  PF_ITEM_00286: "Fri",
  PF_ITEM_00287: "Sat",
  PF_ITEM_00288: "Size",
  PF_ITEM_00289: "Order Entry within Lead Time",
  PF_ITEM_00290: "Order Change",
  PF_ITEM_00291: "Add",
  PF_ITEM_00292: "Change",
  PF_ITEM_00293: "Delete",
  PF_ITEM_00294: "Returned Item",
  PF_ITEM_00295: "Billing Item for Next Month",
  PF_ITEM_00296: "On Hold",
  PF_ITEM_00297: "Advanced Delivery",
  PF_ITEM_00298: "W/ Master",
  PF_ITEM_00299: "W/O Master",
  PF_ITEM_00300: "Pending Approval for New Order Entry",
  PF_ITEM_00301: "Confirmed Order Entry",
  PF_ITEM_00302: "Delete",
  PF_ITEM_00303: "Pending Approval for Order Change/Cancel",
  PF_ITEM_00304: "Pending Confirmation for Rejection",
  PF_ITEM_00305: "Pending Confirmation for Rejection",
  PF_ITEM_00306: "Confirmed Order Entry",
  PF_ITEM_00307: "Delivery Place",
  PF_ITEM_00308: "Delivery Quantity",
  PF_ITEM_00309: "Occurrence Date",
  PF_ITEM_00310: "Order List w/o Deleted Data (CSV)",
  PF_ITEM_00311: "Order List w/ Deleted Data (CSV)",
  PF_ITEM_00312: "Order Change (EXL)",
  PF_ITEM_00313: "Shipment Entry (EXL)",
  PF_ITEM_00314: "New Order Entry (CSV)",
  PF_ITEM_00315: "Total Order Qty.",
  PF_ITEM_00316: "Planned Total Shipment Qty.",
  PF_ITEM_00317: "Actual Total Shipment Qty. ",
  PF_ITEM_00318: "Total Returned Qty.",
  PF_ITEM_00319: "Advanced Search",
  PF_ITEM_00320: "Notifications",
  PF_ITEM_00321: "Confirm Rejection",
  PF_ITEM_00322: "Steel Processing \nComp Code",
  PF_ITEM_00323: "Group Code",
  PF_ITEM_00324: "Group Name (Official)",
  PF_ITEM_00325: "Group Name (Abbrev.)",
  PF_ITEM_00326: "Entry and Display Item",
  PF_ITEM_00327: "-",
  PF_ITEM_00328: "Sourcing Indicator",
  PF_ITEM_00329: "Insourcing Parts",
  PF_ITEM_00330: "Outsourcing Parts (Subcontracted)",
  PF_ITEM_00331: "Outsourcing Parts (Procurement)",
  PF_ITEM_00332: "Outsourcing Parts (Centralized Procurement)",
  PF_ITEM_00333: "Confirmed Order Entry",
  PF_ITEM_00334: "Pending Approval for New Order Entry",
  PF_ITEM_00335: "Pending Approval for Order Change/Cancel",
  PF_ITEM_00336: "Pending Confirmation for Rejection",
  PF_ITEM_00337: "Last Update Date, Hour, Minutes To",
  PF_ITEM_00338: "TWB",
  PF_ITEM_00339: "W/ Master",
  PF_ITEM_00340: "W/O Master",
  PF_ITEM_00341: "Calendar Update Day From",
  PF_ITEM_00342: "Update Hour From",
  PF_ITEM_00343: "Update Minute From",
  PF_ITEM_00344: "Calendar Update Day To",
  PF_ITEM_00345: "Update Hour To",
  PF_ITEM_00346: "Update Minute To",
  PF_ITEM_00347: "Sort",
  PF_ITEM_00351: "Actual Shipment Date",
  PF_ITEM_00352: "Return Date",
  PF_ITEM_00353: "Return",
  PF_ITEM_00354: "Billing for the Following Month",
  PF_ITEM_00355: "On Hold",
  PF_ITEM_00356: "Advanced Delivery Portion",
  PF_ITEM_00357: "Master Reference",
  PF_ITEM_00358: "Ordering Company (Company Name + Department Name)",
  PF_ITEM_00359: "PO Comp 2",
  PF_ITEM_00360: "Delivery Place",
  PF_ITEM_00361: "Ordering Company (Company Name + Department Name)",
  PF_ITEM_00362: "Processing Ordering Company (Company Name + Department Name)",
  PF_ITEM_00363: "Standard",
  PF_ITEM_00366: "Download Batch Status",
  PF_ITEM_00367: "Entry and Display Item",
  PF_ITEM_00368: "Edit Item Entry",
  PF_ITEM_00369: "Format",
  PF_ITEM_00370: "Result",
  PF_ITEM_00371: "Download Conditions",
  PF_ITEM_00372: "Download",
  PF_ITEM_00373: "Completed",
  PF_ITEM_00374: "Processing",
  PF_ITEM_00375: "Upload Lead Time Master (by Item Code)",
  PF_ITEM_00376: "Delivery Place Master",
  PF_ITEM_00378: "Daily Steel Order Notification",
  PF_ITEM_00379: "User Group Master",
  PF_ITEM_00380: "Group",
  PF_ITEM_00381: "Group Name",
  PF_ITEM_00382: "Upload User Group Master",
  PF_ITEM_00388: "Unapproved",
  PF_ITEM_00389: "Approved",
  PF_ITEM_00390: "Unconfirmed",
  PF_ITEM_00391: "Confirmed",
  PF_ITEM_00392: "List of Notifications",
  PF_ITEM_00393: "Request for Approval",
  PF_ITEM_00394: "Unapproved Order",
  PF_ITEM_00395: "Update",
  PF_ITEM_00396: "Reject Confirmation Request",
  PF_ITEM_00397: "Rejected",
  PF_ITEM_00398: "UL Error",
  PF_ITEM_00399: "Comment Update",
  PF_ITEM_00400: "Order Different from Master",
  PF_ITEM_00401: "Lead Time (days)",
  PF_ITEM_00403: "The number of days required to approve for order entry and change with consideration of lead time. 0: Approval are required for order on the delivery date. 1: Approval are required for order from the day before the delivery date. N: Approval are required for order N days or more before the delivery date.",
  PF_ITEM_00409: "Line Number",
  PF_ITEM_00411: "Date Indicator 1 From",
  PF_ITEM_00412: "Date Indicator 1 To",
  PF_ITEM_00413: "Date Indicator 2 From",
  PF_ITEM_00414: "Date Indicator 2 To",
  PF_ITEM_00415: "Last Update Date From",
  PF_ITEM_00416: "Last Update Date To",
  PF_ITEM_00417: "Thickness",
  PF_ITEM_00418: "Thickness",
  PF_ITEM_00419: "Width",
  PF_ITEM_00420: "Width",
  PF_ITEM_00421: "Length",
  PF_ITEM_00422: "Length",
  PF_ITEM_00423: "PO NO. List",
  PF_ITEM_00424: "PO NO. (PF) List",
  PF_ITEM_00425: "PO Comp List",
  PF_ITEM_00426: "SO Comp List",
  PF_ITEM_00427: "Order List w/o Deleted Data (CSV)",
  PF_ITEM_00428: "Order Change (EXL)",
  PF_ITEM_00429: "Shipment Entry (EXL)",
  PF_ITEM_00430: "New Order Entry (CSV)",
  PF_ITEM_00431: "Order List (Excel)",
  PF_ITEM_00432: "Order List w/ Deleted Data (CSV)",
  PF_ITEM_00433: "Processing",
  PF_ITEM_00434: "Error",
  PF_ITEM_00435: "Done",
  PF_ITEM_00436: "Complete",
  PF_ITEM_00437: "Steel Processing Comp List",
  PF_ITEM_00438: "Confirmed Order Entry ",
  PF_ITEM_00439: "Pending Approval for Order Change/Cancel",
  PF_ITEM_00440: "Pending Confirmation for Rejection",
  PF_ITEM_00441: "Excluded from Approval",
  PF_ITEM_00442: "Half-Width Digit",
  PF_ITEM_00443: "Planned Qty. (pc), Planned Qty. (kg)",
  PF_ITEM_00444: "Actual Qty. (pc), Actual Qty. (kg)",
  PF_ITEM_00445: "Order Qty. (pc), Order Qty. (kg)",
}

export default items_en;