const items_ja = {
  PF_ITEM_00001: "ログイン",
  PF_ITEM_00002: "国(日本)",
  PF_ITEM_00003: "国(リージョン2)",
  PF_ITEM_00004: "初めてログインする場合、パスワードを再設定する場合",
  PF_ITEM_00005: "ユーザID",
  PF_ITEM_00006: "パスワード",
  PF_ITEM_00007: "新パスワード",
  PF_ITEM_00008: "新パスワード（確認用）",
  PF_ITEM_00009: "パスワード変更",
  PF_ITEM_00010: "ファイルをドロップしてください。",
  PF_ITEM_00011: "クリックしてファイルを参照。",
  PF_ITEM_00012: "「ファイルを選択してください。」",
  PF_ITEM_00013: "全",
  PF_ITEM_00014: "件",
  PF_ITEM_00015: "ユーザ情報",
  PF_ITEM_00016: "名前",
  PF_ITEM_00017: "会社",
  PF_ITEM_00018: "メールアドレス",
  PF_ITEM_00019: "{0}さん、ようこそ！左側の業務メニューを選択し進めてください。",
  PF_ITEM_00020: "企業マスタ ",
  PF_ITEM_00021: "こちらをクリックして検索条件を入力してください。",
  PF_ITEM_00022: "企業コード",
  PF_ITEM_00023: "企業名(表示用)",
  PF_ITEM_00024: "TDB企業コード",
  PF_ITEM_00025: "郵便番号",
  PF_ITEM_00026: "住所",
  PF_ITEM_00027: "検索結果",
  PF_ITEM_00028: "企業名(正式名称)",
  PF_ITEM_00029: "企業種別",
  PF_ITEM_00030: "備考",
  PF_ITEM_00031: "詳細",
  PF_ITEM_00032: "企業マスタ照会",
  PF_ITEM_00033: "企業識別コード",
  PF_ITEM_00034: "取引先(OEM企業)",
  PF_ITEM_00035: "取引先(紐づけ企業)",
  PF_ITEM_00036: "データ連携方法",
  PF_ITEM_00037: "企業マスタ新規作成",
  PF_ITEM_00038: "企業",
  PF_ITEM_00039: "企業マスタ更新",
  PF_ITEM_00040: "企業マスタ検索結果画面",
  PF_ITEM_00041: "企業マスタ検索画面",
  PF_ITEM_00042: "鉄鋼商社",
  PF_ITEM_00043: "鉄鋼メーカー",
  PF_ITEM_00044: "コイルセンター",
  PF_ITEM_00045: "PF注文番号",
  PF_ITEM_00046: "客先注文番号",
  PF_ITEM_00047: "マスタ参照",
  PF_ITEM_00048: "品目コード",
  PF_ITEM_00049: "品目名",
  PF_ITEM_00050: "用途",
  PF_ITEM_00051: "注文企業コード",
  PF_ITEM_00052: "注文企業名",
  PF_ITEM_00053: "注文グループコード",
  PF_ITEM_00054: "注文グループ名",
  PF_ITEM_00055: "注文企業2コード",
  PF_ITEM_00056: "注文企業2名",
  PF_ITEM_00057: "納入先コード",
  PF_ITEM_00058: "納入先名",
  PF_ITEM_00059: "納入先住所",
  PF_ITEM_00060: "受注企業コード",
  PF_ITEM_00061: "受注企業名",
  PF_ITEM_00062: "受注グループコード",
  PF_ITEM_00063: "受注グループ名",
  PF_ITEM_00064: "加工受注企業コード",
  PF_ITEM_00065: "加工受注企業名",
  PF_ITEM_00066: "加工受注グループコード",
  PF_ITEM_00067: "加工受注グループ名",
  PF_ITEM_00068: "加工形状",
  PF_ITEM_00069: "規格",
  PF_ITEM_00070: "板厚",
  PF_ITEM_00071: "板幅",
  PF_ITEM_00072: "長さ",
  PF_ITEM_00073: "寸法4",
  PF_ITEM_00074: "寸法5",
  PF_ITEM_00075: "リードタイム",
  PF_ITEM_00076: "納入日",
  PF_ITEM_00077: "納入時間",
  PF_ITEM_00078: "注文員数(pc)",
  PF_ITEM_00079: "注文質量(kg)",
  PF_ITEM_00080: "関連注文番号",
  PF_ITEM_00081: "取引形態",
  PF_ITEM_00082: "注文企業管理欄",
  PF_ITEM_00083: "受注企業管理欄",
  PF_ITEM_00084: "特記事項",
  PF_ITEM_00085: "ステータス",
  PF_ITEM_00086: "最終更新ユーザ",
  PF_ITEM_00087: "コメント履歴",
  PF_ITEM_00088: "コメント追記",
  PF_ITEM_00089: "保存",
  PF_ITEM_00090: "閉じる",
  PF_ITEM_00091: "注文処理区分",
  PF_ITEM_00092: "変更対象",
  PF_ITEM_00093: "＋",
  PF_ITEM_00094: "ー",
  PF_ITEM_00095: "再変更",
  PF_ITEM_00096: "承認",
  PF_ITEM_00097: "棄却",
  PF_ITEM_00098: "確認",
  PF_ITEM_00099: "出荷予定・実績区分",
  PF_ITEM_00100: "出荷予定日",
  PF_ITEM_00101: "予定合計員数(pc)",
  PF_ITEM_00102: "予定合計質量(kg)",
  PF_ITEM_00103: "出荷処理区分",
  PF_ITEM_00104: "製品番号(梱包No.)",
  PF_ITEM_00105: "予定内訳員数(pc)",
  PF_ITEM_00106: "予定内訳質量(kg)",
  PF_ITEM_00107: "検査番号",
  PF_ITEM_00108: "出荷実績日",
  PF_ITEM_00109: "実績合計員数(pc)",
  PF_ITEM_00110: "実績合計質量(kg)",
  PF_ITEM_00111: "出荷実績",
  PF_ITEM_00112: "出荷予定",
  PF_ITEM_00113: "実績内訳員数(pc)",
  PF_ITEM_00114: "実績内訳質量(kg)",
  PF_ITEM_00115: "キャンセル",
  PF_ITEM_00116: "実績へコピー",
  PF_ITEM_00117: "当月請求対象",
  PF_ITEM_00118: "返品日",
  PF_ITEM_00119: "返品員数(pc)",
  PF_ITEM_00120: "返品質量(kg)",
  PF_ITEM_00121: "注文情報",
  PF_ITEM_00122: "注文変更",
  PF_ITEM_00123: "出荷情報",
  PF_ITEM_00124: "当月請求対象",
  PF_ITEM_00125: "リードタイムマスタ",
  PF_ITEM_00126: "注文企業",
  PF_ITEM_00127: "注文グループ",
  PF_ITEM_00128: "注文企業2",
  PF_ITEM_00129: "納入先",
  PF_ITEM_00130: "受注企業",
  PF_ITEM_00131: "受注グループ",
  PF_ITEM_00132: "加工受注企業",
  PF_ITEM_00133: "加工受注グループ",
  PF_ITEM_00134: "日付区分",
  PF_ITEM_00135: "日付区分2",
  PF_ITEM_00136: "最終更新日時",
  PF_ITEM_00137: "時",
  PF_ITEM_00138: "分",
  PF_ITEM_00139: "返品/翌月請求対象",
  PF_ITEM_00140: "ソートキー",
  PF_ITEM_00141: "昇順",
  PF_ITEM_00142: "降順",
  PF_ITEM_00143: "共通納期管理",
  PF_ITEM_00144: "～",
  PF_ITEM_00145: "グループマスタアップロード",
  PF_ITEM_00146: "グループマスタ",
  PF_ITEM_00149: "企業名",
  PF_ITEM_00150: "納入先マスタ",
  PF_ITEM_00151: "ファイル",
  PF_ITEM_00152: "共通納期管理表",
  PF_ITEM_00153: "注文 pc",
  PF_ITEM_00154: "注文 kg",
  PF_ITEM_00155: "注文番号",
  PF_ITEM_00156: "出荷 pc",
  PF_ITEM_00157: "出荷 kg",
  PF_ITEM_00158: "出荷実績(出荷予定)登録・変更",
  PF_ITEM_00159: "出荷実績(出荷予定)登録・変更 アップロード",
  PF_ITEM_00160: "アップロード中です。アップロード状況はアップロードバッチ照会画面をご確認ください。",
  PF_ITEM_00161: "リードタイムマスタ(品目コード別)",
  PF_ITEM_00163: "アップロードを完了しました",
  PF_ITEM_00164: "操作",
  PF_ITEM_00165: "No.",
  PF_ITEM_00166: "開始時間",
  PF_ITEM_00167: "完了時間",
  PF_ITEM_00168: "ファイル名",
  PF_ITEM_00169: "全件数",
  PF_ITEM_00170: "登録件数",
  PF_ITEM_00171: "エラー件数",
  PF_ITEM_00172: "処理種別",
  PF_ITEM_00173: "納入先名（表示用）",
  PF_ITEM_00174: "納入先名（正式名称）",
  PF_ITEM_00175: "アップロードバッチ照会",
  PF_ITEM_00178: "日次鋼材注文 登録・変更アップロード",
  PF_ITEM_00179: "電話番号",
  PF_ITEM_00180: "ロケーションコード",
  PF_ITEM_00181: "ロケーション名",
  PF_ITEM_00182: "ロケーションマスタ照会",
  PF_ITEM_00183: "ロケーション",
  PF_ITEM_00184: "ロケーションマスタ新規作成",
  PF_ITEM_00185: "ロケーションマスタ検索結果画面",
  PF_ITEM_00186: "ロケーションマスタ検索画面",
  PF_ITEM_00187: "ロケーションマスタ更新",
  PF_ITEM_00188: "ユーザ名",
  PF_ITEM_00189: "ロール",
  PF_ITEM_00190: "納入先マスタアップロード",
  PF_ITEM_00191: "ユーザマスタ新規作成",
  PF_ITEM_00192: "※入力形式：姓 + 半角スペース/全角スペース + 名",
  PF_ITEM_00193: "ユーザマスタ検索結果画面",
  PF_ITEM_00194: "ユーザマスタ検索画面",
  PF_ITEM_00195: "ユーザ",
  PF_ITEM_00196: "ユーザマスタ照会",
  PF_ITEM_00197: "ユーザマスタ更新",
  PF_ITEM_00198: "ユーザマスタ照会画面",
  PF_ITEM_00199: "ロケーションマスタ照会画面",
  PF_ITEM_00200: "企業マスタ照会画面",
  PF_ITEM_00201: "エラーメッセージ",
  PF_ITEM_00202: "品目 登録",
  PF_ITEM_00203: "品目種別",
  PF_ITEM_00204: "検索基準日",
  PF_ITEM_00205: "製作所",
  PF_ITEM_00206: "代表車種",
  PF_ITEM_00207: "モデルシリーズ",
  PF_ITEM_00208: "鉄鋼商社名",
  PF_ITEM_00209: "母材コード",
  PF_ITEM_00210: "得意先",
  PF_ITEM_00211: "加工先企業名\n(加工受注企業名)",
  PF_ITEM_00212: "加工先拠点名",
  PF_ITEM_00213: "ミルコード",
  PF_ITEM_00214: "ミル名称",
  PF_ITEM_00215: "品種コード",
  PF_ITEM_00216: "品種名",
  PF_ITEM_00217: "目付",
  PF_ITEM_00218: "薄目引",
  PF_ITEM_00219: "高炉母材管理コード",
  PF_ITEM_00220: "母材納入先",
  PF_ITEM_00222: "最上位品目",
  PF_ITEM_00223: "部品",
  PF_ITEM_00224: "鋼材",
  PF_ITEM_00225: "母材",
  PF_ITEM_00226: "部品番号",
  PF_ITEM_00227: "条件を複数指定する場合",
  PF_ITEM_00228: "品名",
  PF_ITEM_00229: "部品メーカーの場合、品名",
  PF_ITEM_00230: "商社の場合、規格＋サイズ",
  PF_ITEM_00231: "規格＋サイズ",
  PF_ITEM_00232: "コイル",
  PF_ITEM_00233: "シート",
  PF_ITEM_00234: "ブランク",
  PF_ITEM_00235: "台形",
  PF_ITEM_00236: "直送",
  PF_ITEM_00237: "鋼管",
  PF_ITEM_00238: "棒鋼",
  PF_ITEM_00239: "線材",
  PF_ITEM_00240: "その他",
  PF_ITEM_00241: "バージョン",
  PF_ITEM_00242: "有効期間開始日",
  PF_ITEM_00243: "有効期間終了日",
  PF_ITEM_00244: "品目 登録アップロード",
  PF_ITEM_00245: "エラーメッセージリスト",
  PF_ITEM_00246: "全て",
  PF_ITEM_00247: "最上位",
  PF_ITEM_00248: "品目 登録新規作成",
  PF_ITEM_00249: "品目名(表示用)",
  PF_ITEM_00250: "品目名(正式名称)",
  PF_ITEM_00251: "管理単位",
  PF_ITEM_00252: "取引先企業コード",
  PF_ITEM_00253: "品目連関種別",
  PF_ITEM_00254: "連関対象品目",
  PF_ITEM_00255: "連関対象外品目",
  PF_ITEM_00256: "スポット鋼材",
  PF_ITEM_00257: "変更理由",
  PF_ITEM_00258: "鉄鋼商社コード",
  PF_ITEM_00259: "GLNO",
  PF_ITEM_00260: "MAX外径",
  PF_ITEM_00261: "MIN外径",
  PF_ITEM_00262: "MAX単重",
  PF_ITEM_00263: "MIN単重",
  PF_ITEM_00264: "梱包枚数",
  PF_ITEM_00265: "特別仕様",
  PF_ITEM_00266: "注文丸め単位",
  PF_ITEM_00267: "MIN注文重量",
  PF_ITEM_00268: "外板区分",
  PF_ITEM_00269: "高炉コード",
  PF_ITEM_00270: "資材コード",
  PF_ITEM_00271: "通知種類",
  PF_ITEM_00272: "加工先拠点コード",
  PF_ITEM_00273: "品目",
  PF_ITEM_00274: "取引先企業名",
  PF_ITEM_00275: "通知発生日",
  PF_ITEM_00276: "通知一覧検索",
  PF_ITEM_00277: "コメント",
  PF_ITEM_00278: "納入先コード",
  PF_ITEM_00280: "詳細項目",
  PF_ITEM_00281: "日",
  PF_ITEM_00282: "月",
  PF_ITEM_00283: "火",
  PF_ITEM_00284: "水",
  PF_ITEM_00285: "木",
  PF_ITEM_00286: "金",
  PF_ITEM_00287: "土",
  PF_ITEM_00288: "サイズ",
  PF_ITEM_00289: "リードタイム内登録",
  PF_ITEM_00290: "注文変更",
  PF_ITEM_00291: "追加",
  PF_ITEM_00292: "変更",
  PF_ITEM_00293: "削除",
  PF_ITEM_00294: "返品",
  PF_ITEM_00295: "翌月請求対象",
  PF_ITEM_00296: "保留",
  PF_ITEM_00297: "先行納入分",
  PF_ITEM_00298: "あり",
  PF_ITEM_00299: "なし",
  PF_ITEM_00300: "承認待ち（新規登録）",
  PF_ITEM_00301: "注文確定",
  PF_ITEM_00302: "削除",
  PF_ITEM_00303: "承認待ち（変更・キャンセル）",
  PF_ITEM_00304: "棄却確認待ち",
  PF_ITEM_00305: "登録棄却確認待ち",
  PF_ITEM_00306: "注文確定",
  PF_ITEM_00307: "納入先",
  PF_ITEM_00308: "納入数量",
  PF_ITEM_00309: "発生日時",
  PF_ITEM_00310: "注文照会_削除データなし(CSV)",
  PF_ITEM_00311: "注文照会_削除データあり(CSV)",
  PF_ITEM_00312: "注文変更(Excel)",
  PF_ITEM_00313: "出荷予定・実績登録(Excel)",
  PF_ITEM_00314: "新規注文登録(CSV)",
  PF_ITEM_00315: "合計注文員数・質量",
  PF_ITEM_00316: "合計出荷予定員数・質量",
  PF_ITEM_00317: "合計出荷実績員数・質量",
  PF_ITEM_00318: "合計返品員数・質量",
  PF_ITEM_00320: "通知一覧",
  PF_ITEM_00322: "加工先企業コード\n(加工受注企業コード)",
  PF_ITEM_00323: "グループコード",
  PF_ITEM_00324: "グループ名(正式名称)",
  PF_ITEM_00325: "グループ名(表示用)",
  PF_ITEM_00326: "品目 登録照会",
  PF_ITEM_00327: "無し",
  PF_ITEM_00328: "内外製区分",
  PF_ITEM_00329: "内製部品",
  PF_ITEM_00330: "外製/外注部品",
  PF_ITEM_00331: "外製/購入部品",
  PF_ITEM_00332: "外製/集中購入部品",
  PF_ITEM_00333: "注文確定",
  PF_ITEM_00334: "承認待ち（新規登録）",
  PF_ITEM_00335: "承認待ち（変更・キャンセル）",
  PF_ITEM_00336: "棄却確認待ち",
  PF_ITEM_00337: "最終更新日時分To",
  PF_ITEM_00338: "TWB",
  PF_ITEM_00339: "マスタ参照あり",
  PF_ITEM_00340: "マスタ参照なし",
  PF_ITEM_00341: "最終更新日_Fromカレンダー",
  PF_ITEM_00342: "最終更新時_From",
  PF_ITEM_00343: "最終更新分_From",
  PF_ITEM_00344: "最終更新日_Toカレンダー",
  PF_ITEM_00345: "最終更新時_To",
  PF_ITEM_00346: "最終更新分_To",
  PF_ITEM_00347: "ソート順",
  PF_ITEM_00351: "出荷実績日",
  PF_ITEM_00352: "返品日",
  PF_ITEM_00353: "返品",
  PF_ITEM_00354: "翌月請求対象",
  PF_ITEM_00355: "保留",
  PF_ITEM_00356: "先行納入分",
  PF_ITEM_00357: "マスタ\n参照",
  PF_ITEM_00358: "注文企業(企業名 + 部署名)",
  PF_ITEM_00359: "注文企業2",
  PF_ITEM_00360: "納入先",
  PF_ITEM_00361: "受注企業(企業名 + 部署名)",
  PF_ITEM_00362: "加工受注企業(企業名 + 部署名)",
  PF_ITEM_00363: "規格",
  PF_ITEM_00366: "ダウンロードバッチ照会",
  PF_ITEM_00367: "品目 登録結果",
  PF_ITEM_00368: "品目 登録編集",
  PF_ITEM_00369: "フォーマット",
  PF_ITEM_00370: "処理結果",
  PF_ITEM_00371: "ダウンロード条件",
  PF_ITEM_00372: "ダウンロード検索",
  PF_ITEM_00373: "完了",
  PF_ITEM_00374: "実行中",
  PF_ITEM_00375: "リードタイムマスタ(品目コード別)アップロード",
  PF_ITEM_00376: "納入先マスタ",
  PF_ITEM_00378: "日次鋼材注文 通知",
  PF_ITEM_00379: "ユーザ・グループマスタ",
  PF_ITEM_00380: "グループ",
  PF_ITEM_00381: "グループ名",
  PF_ITEM_00382: "ユーザ・グループマスタアップロード",
  PF_ITEM_00388: "未承認",
  PF_ITEM_00389: "承認済",
  PF_ITEM_00390: "未確認",
  PF_ITEM_00391: "確認済",
  PF_ITEM_00392: "通知一覧検索",
  PF_ITEM_00393: "承認依頼",
  PF_ITEM_00394: "未承認注文",
  PF_ITEM_00395: "更新",
  PF_ITEM_00396: "棄却確認依頼",
  PF_ITEM_00397: "棄却通知",
  PF_ITEM_00398: "ULエラー",
  PF_ITEM_00399: "コメント更新",
  PF_ITEM_00400: "マスタ情報と異なる注文",
  PF_ITEM_00401: "リードタイム(日数)",
  PF_ITEM_00403: "リードタイムを考慮し、注文登録・変更に対する承認を必要とする日数を指定する。<br>・0：納入日当日の注文登録・変更に対して承認が必要 <br>・1：納入日前日以降の注文登録・変更に対して承認が必要 <br>・N：納入日N日前以降の注文登録・変更に対して承認が必要",
  PF_ITEM_00409: "行番号",
  PF_ITEM_00411: "対象期間1_From",
  PF_ITEM_00412: "対象期間1_To",
  PF_ITEM_00413: "対象期間2_From",
  PF_ITEM_00414: "対象期間2_To",
  PF_ITEM_00415: "最終更新日時_From",
  PF_ITEM_00416: "最終更新日時_To",
  PF_ITEM_00417: "板厚_From",
  PF_ITEM_00418: "板厚_To",
  PF_ITEM_00419: "板幅_From",
  PF_ITEM_00420: "板幅_To",
  PF_ITEM_00421: "長さ_From",
  PF_ITEM_00422: "長さ_To",
  PF_ITEM_00423: "客先注文番号リスト",
  PF_ITEM_00424: "PF注文番号リスト",
  PF_ITEM_00425: "注文企業リスト",
  PF_ITEM_00426: "受注企業リスト",
  PF_ITEM_00427: "注文照会_削除データなし(CSV)",
  PF_ITEM_00428: "注文変更(Excel)",
  PF_ITEM_00429: "出荷予定・実績登録(Excel)",
  PF_ITEM_00430: "新規注文登録(CSV)",
  PF_ITEM_00431: "注文照会(Excel)",
  PF_ITEM_00432: "注文照会_削除データあり(CSV)",
  PF_ITEM_00433: "実行中",
  PF_ITEM_00434: "作成エラー",
  PF_ITEM_00435: "作成済",
  PF_ITEM_00436: "ダウンロード済",
  PF_ITEM_00437: "加工受注企業リスト",
  PF_ITEM_00438: "注文確定",
  PF_ITEM_00439: "承認待ち（変更・キャンセル)",
  PF_ITEM_00440: "棄却確認待ち",
  PF_ITEM_00441: "承認対象外",
  PF_ITEM_00442: "半角数字",
  PF_ITEM_00443: "出荷予定内訳員数(pc)、出荷予定内訳質量(kg)",
  PF_ITEM_00444: "出荷実績内訳員数(pc)、出荷実績内訳質量(kg)",
  PF_ITEM_00445: "注文員数(pc)、注文質量(kg)",
}

export default items_ja;